<template>
  <div class="Btra">
    <v-row no-gutters>
      <!-- Botonera -->
      <template v-for="(elem, idx) in btra_particular">       
        <template v-if="typeof elem.accion === 'number'">         
          <v-btn      
            v-if="show(elem)"
            :key="idx"
            v-bind="design"
            :style="elem.styB? elem.styB : 'margin-left:5px'"
            :title="elem.title? elem.title : (elem.btn? $cfg.btn[elem.btn].tooltip : '')"
            :disabled="disabled(elem)"
            @click="event_btra(elem.accion)">

              <!-- Label -->
              <template v-if="design['label'] && elem.texto">
                <span :style="elem.styT? elem.styT : ''">{{ elem.texto }}</span>
              </template>

              <!-- Icono -->
              <v-icon v-if="elem.icono || elem.btn" v-bind="design.icons" :style="elem.styI? elem.styI : ''">
                
                {{ elem.icono? elem.icono : $cfg.btn[elem.btn].icon }}
              </v-icon>
          </v-btn>
        </template>

        
        <template v-else>          
          <slot name="btns_extra" :Sitem="elem">
            <v-btn
     
              v-if="show(elem)"   
              v-bind="design"                      
              :key="idx"              
              :style="elem.styB? elem.styB : 'margin-left:5px'"
              :title="elem.title? elem.title : (elem.btn? $cfg.btn[elem.btn].tooltip : '')"
              :disabled="disabled(elem)"
              @click="event_btra(elem.accion)">
              

                <!-- Label -->
                <template v-if="design['label'] && elem.texto">
                  <span :style="elem.styT? elem.styT : ''">{{ elem.texto }}</span>
                </template>

                <!-- Icono -->
                <v-icon v-if="elem.icono || elem.btn" v-bind="design.icons" :style="elem.styI? elem.styI : ''">
                  {{ elem.icono? elem.icono : $cfg.btn[elem.btn].icon }}
                </v-icon>
            </v-btn>
          </slot>
        </template>
      </template>
    </v-row>
  </div>
</template>

<script>

  export default {
    name: "btra",
    props: {
      btra_particular: { type: [Array, Object], default: null },
      btra_modulo: { type: String, default: "" },
      nopesoShow: { type: Number, default: 1 }
    },

    data() {
      return {
        design: this.$cfg.btra_cfg[this.btra_modulo]
      };
    },


    methods: {
      // gestor de eventos
      event_btra(accion) {
        console.log("*** event_btra. Emit btra: " + accion + " ***");
        this.$emit("onEvent", { accion:accion });
      },


      // muestro el botón SÍ o NO (independientemente si está disabled)
      show(elem) {             
        switch (this.nopesoShow) {
        case 0:          
          if (typeof elem.view!== 'undefined') return Boolean(elem.view);
          if (typeof elem.show!== 'undefined') return Boolean(elem.show);
          return true;     

        case 1:
          if (typeof elem.view!== 'undefined') return Boolean(elem.view);
          if (typeof elem.show!== 'undefined') return true;
          return true;
        }       
      },


      // deshabilito el botón SÍ o NO
      disabled(elem) { 
        switch (this.nopesoShow) {
        case 0:
          if (typeof elem.show!== 'undefined' && !elem.show) return true;
          if (typeof elem.disabled!== 'undefined') return Boolean(elem.disabled);
          return false;         
          
        case 1:          
          if (typeof elem.show!== 'undefined' && !elem.show) return true;
          if (typeof elem.disabled!== 'undefined') return Boolean(elem.disabled);
          return false;
        }        
      },

    }
  };
</script>
